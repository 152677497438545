import { render, staticRenderFns } from "./MakbuzIndirList.vue?vue&type=template&id=4806b25e&scoped=true"
import script from "./MakbuzIndirList.vue?vue&type=script&lang=ts"
export * from "./MakbuzIndirList.vue?vue&type=script&lang=ts"
import style0 from "./MakbuzIndirList.vue?vue&type=style&index=0&id=4806b25e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4806b25e",
  null
  
)

export default component.exports